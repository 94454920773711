// NPM Requirements
import React, { Component } from 'react';

class BoeingHome extends Component {
  render() {
    return (
      <React.Fragment>
        <header>
          <div className='Overlay'>
            <h1>Boeing</h1>
          </div>
        </header>

        <div className='Info'>
          <p>Sorry, something went wrong.</p><br/>
          <a href="/">Back to Home</a>
        </div>

      </React.Fragment>
    );
  }
}

export default BoeingHome;
